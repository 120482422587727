import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FileComponent } from '@enginuity/core/molecules/file/file.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';

import { forkJoin, Observable } from 'rxjs';
import { BoxTabComponent } from '@enginuity/core/molecules/box-tab/box-tab.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import {
  FileForUpload,
  ImageEntry,
  MediaImage,
  MediaItemFileType,
  MediaItemUploadSource,
  MediaThumbnail,
  MediaVideo,
  VideoEntry,
} from '@services/media-services/models';
import { MediaService } from '@services/media-services/media.service';
import { MediaTileComponent } from '@enginuity/product/organisms/media/media-tile/media-tile.component';
import { UrlService } from '@services/auth-services/url.service';
import {
  getImageEntryFromMediaImage,
  getMediaTypeFromFileType,
  getThumbnailFromImage,
  getThumbnailFromLocalFile,
  getThumbnailFromVideo,
  getUploadFilesForMediaType,
  getVideoEntryFromMediaVideo,
} from '@services/media-services/media.utils';
import { NotifyService } from '@services/core-services/notify.service';
import { MediaGalleryBoxComponent } from '@enginuity/media/organisms/media-gallery-box/media-gallery-box.component';
import { sortEntries } from '@services/core-services/utils';

enum SourceType {
  Upload = 'Upload',
  External = 'External',
}

@Component({
  selector: 'app-media-uploader',
  standalone: true,
  imports: [
    FileComponent,
    DragDropModule,
    FormsModule,
    NgForOf,
    JsonPipe,
    BoxTabComponent,
    NgIf,
    InputsComponent,
    ReactiveFormsModule,
    ButtonsComponent,
    MediaTileComponent,
    MediaGalleryBoxComponent,
  ],
  templateUrl: './media-uploader.component.html',
  styleUrls: ['./media-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaUploaderComponent implements OnChanges {
  protected readonly SourceType = SourceType;

  protected imageThumbnails: MediaThumbnail[] = [];
  protected videoThumbnails: MediaThumbnail[] = [];

  @Output() onImagesUpload: EventEmitter<ImageEntry[]> = new EventEmitter<ImageEntry[]>();
  @Output() onVideosUpload: EventEmitter<VideoEntry[]> = new EventEmitter<VideoEntry[]>();
  @Output() onRemoveImage: EventEmitter<ImageEntry[]> = new EventEmitter<ImageEntry[]>();
  @Output() onRemoveVideo: EventEmitter<VideoEntry[]> = new EventEmitter<VideoEntry[]>();
  @Output() onUploadStart = new EventEmitter();
  @Output() onUploadEnd = new EventEmitter();
  @Output() onImagesSort = new EventEmitter<ImageEntry[]>();
  @Output() onVideosSort = new EventEmitter<VideoEntry[]>();

  @Input() multiple = true;
  @Input() sizeLabel = '';
  @Input() videos: VideoEntry[] = [];
  @Input() images: ImageEntry[] = [];

  get externalUrl(): string {
    return this.externalUrlCtrl?.value;
  }

  protected externalUrlCtrl = new FormControl();
  protected sourceType: SourceType = SourceType.Upload;
  protected invalidUrl: boolean = false;
  protected imagesForUpload: FileForUpload[] = [];
  protected videosForUpload: FileForUpload[] = [];

  protected tabs: any = [
    {
      label: 'Upload',
      id: 'Upload',
      active: true,
    },
    {
      label: 'External URL',
      id: 'External',
      active: false,
    },
  ];

  constructor(
    private readonly urlService: UrlService,
    private readonly notify: NotifyService,
    private readonly mediaService: MediaService
  ) {
    this.externalUrlCtrl.valueChanges.subscribe(value => this.parseUrl(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    const images = (changes['images']?.currentValue as ImageEntry[]) || [];
    const videos = (changes['videos']?.currentValue as VideoEntry[]) || [];
    this.imageThumbnails = images.map(image => getThumbnailFromImage(image));
    this.videoThumbnails = videos.map(video => getThumbnailFromVideo(video));
  }

  onImageListSort(images: MediaThumbnail[]) {
    this.images = sortEntries(this.images, images) as ImageEntry[];
    this.onImagesSort.emit(this.images);
  }

  onVideoListSort(videos: MediaThumbnail[]) {
    this.videos = sortEntries(this.videos, videos) as VideoEntry[];
    this.onVideosSort.emit(this.videos);
  }

  async handleFileChange(list: FileList | null) {
    if (!list) return;

    this.onUploadStart.emit();

    const files: any = Array.from(list);
    const images = getUploadFilesForMediaType(MediaItemFileType.Image, files);
    const videos = getUploadFilesForMediaType(MediaItemFileType.Video, files);

    this.imageThumbnails = this.imageThumbnails.concat(
      images.map(img => getThumbnailFromLocalFile(img))
    );
    this.videoThumbnails = this.videoThumbnails.concat(
      videos.map(video => getThumbnailFromLocalFile(video))
    );

    this.imagesForUpload = [...this.imagesForUpload, ...images];
    this.videosForUpload = [...this.videosForUpload, ...videos];

    let imagesDone = !this.imagesForUpload.length;
    let videosDone = !this.videosForUpload.length;

    const imageUploads = this.imagesForUpload.map((file: FileForUpload) => this.getUpload(file));
    const videoUploads = this.videosForUpload.map((file: FileForUpload) => this.getUpload(file));

    forkJoin(imageUploads).subscribe(results => {
      imagesDone = true;
      const completed = results.filter(r => !r.error);
      this.onImagesUpload.emit(
        completed.map((image: MediaImage) => getImageEntryFromMediaImage(image))
      );
      this.imagesForUpload = this.imagesForUpload
        .filter(i => !completed.some(c => c.file_name === i.file.name))
        .map(i => ({ ...i, error: true }));

      if (imagesDone && videosDone) {
        this.onUploadEnd.emit();

        if (this.hasErrors(results)) {
          this.notify.error('Some files are not supported and cannot be uploaded');
        }
      }
    });

    forkJoin(videoUploads).subscribe(results => {
      videosDone = true;
      const completed = results.filter(r => !r.error);
      this.onVideosUpload.emit(
        completed.map((video: MediaVideo) => getVideoEntryFromMediaVideo(video))
      );
      this.videosForUpload = this.videosForUpload
        .filter(i => !completed.some(c => c.file_name === i.file.name))
        .map(i => ({ ...i, error: true }));

      if (imagesDone && videosDone) {
        this.onUploadEnd.emit();

        if (this.hasErrors(results)) {
          this.notify.error('Some files are not supported and cannot be uploaded');
        }
      }
    });
  }

  async onDeleteImage(image: MediaThumbnail) {
    if (!image.temporary) {
      this.images = this.images.filter(img => img.file_id !== image.file_id);
      this.onRemoveImage.emit(this.images);
    }
  }

  async onDeleteVideo(video: MediaThumbnail) {
    if (!video.temporary) {
      this.videos = this.videos.filter(video => video.file_id !== video.file_id);
      this.onRemoveVideo.emit(this.videos);
    }
  }

  onSourceTypeChange(id: any) {
    this.sourceType = id;
  }

  uploadFromExternalUrl() {
    this.mediaService
      .uploadFromExternalUrl({
        upload_source: MediaItemUploadSource.ExternalUrl,
        external_url: this.externalUrl,
      })
      .subscribe(
        (image: MediaImage) => {
          const entry = getImageEntryFromMediaImage(image);
          // For now only images can be uploaded via external url
          this.externalUrlCtrl.setValue('');
          this.onImagesUpload.emit([entry]);
        },
        () =>
          this.notify.error('Unable to upload image from external url. Check if image is valid.')
      );
  }

  private parseUrl(url: string) {
    this.invalidUrl = false;
    if (!url) return;

    if (this.urlService.isValidUrl(url)) {
      // Parse
    } else {
      this.invalidUrl = true;
    }
  }

  private getUpload(upload: FileForUpload): Observable<any> {
    const { file } = upload;
    const { size, name } = file;
    const file_type = getMediaTypeFromFileType(file.type);

    return this.mediaService.upload({
      file,
      file_type,
      upload_source: MediaItemUploadSource.LocalStorage,
      file_size: size,
      file_name: name,
    });
  }

  private hasErrors(results: any[]) {
    const withError = results.find(x => x.error);
    return !!withError;
  }
}
